import Plot from "./TimelinePlot";
import { Badge, Flex, Skeleton, Text, Spinner, VStack, HStack } from "@chakra-ui/react";
import { secondsToDate, stringDurationToHMSDuration, secondsToHMSDuration } from "../../utils/time";
import "./Timeline.css";
import { Moment, RecommendedMoment } from "../../models/moment";
import { SelectedMoment } from "../../models/selectedMoment";

type Props = {
  graphSeekCallback: (seconds: number) => void;
  moments: Moment[];
  recommendedMoments: RecommendedMoment[];
  playTime: number;
  videoInfo: any;
  focusedCallback: (selectedMoment: SelectedMoment | null) => void;
  selectedMoment: Pick<Moment, "id" | "start_time" | "end_time"> | null;
  selectedMomentType: "temporary" | "user" | "recommended" | null;
  updateSelectedMoment: (update: Pick<Moment, "start_time" | "end_time">) => void;
  signalLoading: boolean;
  signalError: boolean;
  signalData: Map<string, { x: Date[]; y: number[] }>;
};

export const Timeline = (props: Props) => {
  const {
    graphSeekCallback,
    moments,
    recommendedMoments,
    playTime,
    videoInfo,
    focusedCallback,
    selectedMoment,
    selectedMomentType,
    updateSelectedMoment,
    signalLoading,
    signalError,
    signalData,
  } = props;

  if (signalError) return <p>Error</p>;

  const datePlayTime = secondsToDate(playTime);
  const formattedTime = secondsToHMSDuration(playTime);

  return (
    <>
      {!videoInfo || signalLoading ? (
        <>
          <VStack m={3} w={"100%"} alignItems={"flex-start"}>
            <HStack w={"fit-content"}>
              <Text fontSize={"sm"} fontWeight={"semibold"}>
                Analyzing...
                {videoInfo?.search_progress ? ` ${videoInfo.search_progress}%` : ""}
              </Text>
              <Spinner size={"sm"} />
            </HStack>
            <Skeleton h={"100%"} py={40} w={"100%"} borderRadius={"md"} />
          </VStack>
        </>
      ) : (
        <VStack m={3} w={"100%"} alignItems={"stretch"}>
          <HStack justifyContent={"space-between"}>
            <HStack alignItems={"center"}>
              <Badge
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"center"}
                borderRadius={"sm"}
                h={6}
                colorScheme={videoInfo.video_type === "VOD" ? "green" : "red"}
                py={1}
                px={2}
                variant={"subtle"}
              >
                {videoInfo.video_type}
              </Badge>
              {videoInfo.status === "in-progress" && (
                <>
                  <Text fontSize={"sm"} fontWeight={"semibold"}>
                    Analyzing...
                    {videoInfo.search_progress ? ` ${videoInfo.search_progress}%` : ""}
                  </Text>
                  <Spinner size={"sm"} />
                </>
              )}
            </HStack>
            <HStack spacing={1}>
              <Text color={"green.300"} fontSize={"sm"}>
                {formattedTime}
              </Text>
              {videoInfo.video_type === "VOD" && (
                <>
                  <Text fontSize={"sm"}>/</Text>
                  <Text fontSize={"sm"}>
                    {stringDurationToHMSDuration(videoInfo.video_duration)}
                  </Text>
                </>
              )}
            </HStack>
          </HStack>
          <Plot
            signalData={signalData}
            graphSeekCallback={graphSeekCallback}
            moments={moments}
            recommendedMoments={recommendedMoments}
            playTime={datePlayTime}
            momentClickCallback={focusedCallback}
            selectedMoment={selectedMoment}
            selectedMomentType={selectedMomentType}
            updateSelectedMoment={updateSelectedMoment}
            isSearchStopped={videoInfo.status === "stopped"}
          />
        </VStack>
      )}
    </>
  );
};

export default Timeline;
