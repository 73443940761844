import { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Flex,
  Text,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  ButtonGroup,
  IconButton,
  Tooltip,
  Button,
  Spinner,
  HStack,
} from "@chakra-ui/react";
import { SearchIcon, AddIcon, MinusIcon } from "@chakra-ui/icons";
import { CloseOne } from "@icon-park/react";
import { secondsToTwitchTime } from "../../utils/time";
import { Message } from "../../models/message";

export type FetchState = "loading" | "success" | "error";

type Props = {
  messages: Message[];
  fetchState: FetchState;
  messageType: string;
  playTime: number;
  seek: (seconds: number) => void;
  isCollapsed: boolean;
};

export const MessageList = (props: Props) => {
  const messageChunkSize = 100;
  const searchDebounceTime = 500;
  const fontSizes = ["xs", "sm", "md", "lg", "xl", "2xl"];
  const { messages, fetchState, messageType, playTime, seek, isCollapsed } = props;
  const [synced, setSynced] = useState(true);
  const [chunkIndex, setChunkIndex] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [renderedSearchTerm, setRenderedSearchTerm] = useState("");
  const [fontSize, setFontSize] = useState("xs");
  const isMouseDown = useRef(false);
  const chatListRef = useRef<HTMLDivElement>(null);
  const scrollTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRenderedSearchTerm(event.target.value.toLowerCase());
  };

  const clearSearch = () => {
    setRenderedSearchTerm("");
  };

  const changeSearchTerm = (newSearchTerm: string) => {
    if (searchTerm !== newSearchTerm) {
      setSearchTerm(newSearchTerm);
      setSynced(newSearchTerm === "");
      setChunkIndex(0);
    }
  };

  const increaseFontSize = () => {
    const currentIndex = fontSizes.indexOf(fontSize);
    if (currentIndex < fontSizes.length - 1) {
      setFontSize(fontSizes[currentIndex + 1]);
    }
  };

  const decreaseFontSize = () => {
    const currentIndex = fontSizes.indexOf(fontSize);
    if (currentIndex > 0) {
      setFontSize(fontSizes[currentIndex - 1]);
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      changeSearchTerm(renderedSearchTerm);
    }, searchDebounceTime);
    return () => clearTimeout(timeoutId);
  }, [renderedSearchTerm]);

  const filteredMessages = useMemo(
    () =>
      searchTerm
        ? messages.filter(
            (message) =>
              message.content.toLowerCase().includes(searchTerm) ||
              message.userName.toLowerCase().includes(searchTerm)
          )
        : messages,
    [messages, searchTerm]
  );

  const chatChunks = useMemo(() => {
    const chunks = [];
    let msgs = filteredMessages;
    while (msgs.length > messageChunkSize) {
      chunks.push({
        start: msgs[0].timestamp,
        messages: msgs.slice(0, messageChunkSize),
      });
      msgs = msgs.slice(messageChunkSize);
    }
    if (msgs.length > 0) chunks.push({ start: msgs[0].timestamp, messages: msgs });
    return chunks;
  }, [filteredMessages]);

  const currentMessages = useMemo(() => {
    if (chunkIndex >= chatChunks.length) return [];
    const before = chunkIndex > 0 ? chatChunks[chunkIndex - 1].messages : [];
    const middle = chatChunks[chunkIndex].messages;
    const after = chunkIndex + 1 < chatChunks.length ? chatChunks[chunkIndex + 1].messages : [];
    return [...before, ...middle, ...after];
  }, [chatChunks, chunkIndex]);

  const handleMouseDown = () => {
    isMouseDown.current = true;
  };

  const handleMouseUp = () => {
    isMouseDown.current = false;
  };

  const handleWheel = () => {
    setSynced(false);
  };

  const handleScrollTimeout = () => {
    scrollTimeout.current = null;
    if (!chatListRef.current) return;
    const currentChunk = chatChunks[chunkIndex].messages;

    if (chunkIndex > 0) {
      const firstMessageId = `${messageType}Message.${currentChunk[0].index}`;
      const firstMessage = document.getElementById(firstMessageId);
      if (!firstMessage) return;
      if (
        firstMessage.getBoundingClientRect().top >
        chatListRef.current.getBoundingClientRect().bottom
      ) {
        setChunkIndex(chunkIndex - 1);
        return;
      }
    }

    if (chunkIndex < chatChunks.length - 1) {
      const lastMessageId = `${messageType}Message.${currentChunk[currentChunk.length - 1].index}`;
      const lastMessage = document.getElementById(lastMessageId);
      if (!lastMessage) return;
      if (
        lastMessage.getBoundingClientRect().bottom < chatListRef.current.getBoundingClientRect().top
      ) {
        setChunkIndex(chunkIndex + 1);
        return;
      }
    }
  };

  const handleScroll = () => {
    if (!scrollTimeout.current) scrollTimeout.current = setTimeout(handleScrollTimeout, 200);
    if (isMouseDown.current) setSynced(false);
  };

  const resumeAutoScroll = () => {
    setSynced(true);
  };

  const handleMessageClick = (seconds: number) => {
    setSynced(false);
    seek(seconds);
  };

  useEffect(() => {
    if (synced && !isCollapsed && messages.length > 0) {
      let chunk = null;
      let id: string | null = null;
      for (let i = 0; i < chatChunks.length; ++i) {
        if (chatChunks[i].start > playTime) {
          if (i === 0) id = `${messageType}Message.chat-start`;
          chunk = Math.max(i - 1, 0);
          break;
        }
      }
      if (chunk === null) chunk = chatChunks.length - 1;
      const chunkMessages = chatChunks[chunk].messages;
      if (id === null) {
        for (let i = 1; i < chunkMessages.length; ++i) {
          if (chunkMessages[i].timestamp > playTime) {
            id = `${messageType}Message.${chunkMessages[i - 1].index}`;
            break;
          }
        }
      }
      if (id === null)
        id = `${messageType}Message.${chunkMessages[chunkMessages.length - 1].index}`;
      setChunkIndex(chunk);
      setTimeout(() => document.getElementById(id as string)?.scrollIntoView(false));
    }
  }, [playTime, synced, isCollapsed, messages, fontSize]);

  return (
    <Flex flexDir={"column"} h={"100%"} bg={"gray.900"} borderRadius={"md"} pb={2}>
      <Flex p={4} position={"sticky"} top={"0"} zIndex={"sticky"} justify={"space-between"}>
        <InputGroup mr={2} size={"sm"}>
          <InputLeftElement pointerEvents="none">
            <SearchIcon pointerEvents="none" />
          </InputLeftElement>
          <Input
            className={"amp-unmask"}
            type={"text"}
            value={renderedSearchTerm}
            onChange={handleSearchChange}
            size={"sm"}
            borderRadius={"md"}
            variant={"filled"}
          />
          {searchTerm && (
            <InputRightElement>
              <IconButton
                aria-label={"Clear filter"}
                icon={<CloseOne size={"24px"} theme={"filled"} fill={"transparent"} />}
                size={"16px"}
                borderRadius={"full"}
                variant={"ghost"}
                onClick={clearSearch}
              />
            </InputRightElement>
          )}
        </InputGroup>
        <ButtonGroup size={"sm"} variant={"outline"} isAttached>
          <Tooltip label={"Decrease text size"}>
            <IconButton
              aria-label={"Decrease text size"}
              icon={<MinusIcon />}
              onClick={decreaseFontSize}
            />
          </Tooltip>
          <Tooltip label={"Increase text size"}>
            <IconButton
              aria-label={"Increase text size"}
              icon={<AddIcon />}
              onClick={increaseFontSize}
            />
          </Tooltip>
        </ButtonGroup>
      </Flex>
      <Box
        ref={chatListRef}
        overflowY={"auto"}
        flex={"1"}
        className={"chat-list"}
        onScroll={handleScroll}
        onWheel={handleWheel}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onTouchMove={handleWheel}
      >
        {filteredMessages.length > 0 ? (
          <>
            {searchTerm === "" && chunkIndex === 0 && (
              <Box h={"100%"} id={`${messageType}Message.chat-start`} />
            )}
            {currentMessages.map((message) => (
              <Text
                key={message.index}
                onClick={() => handleMessageClick(message.timestamp)}
                id={`${messageType}Message.${message.index}`}
                fontSize={fontSize}
                fontWeight={"normal"}
                _hover={{ bg: "whiteAlpha.200" }}
                px={2}
                mx={2}
                py={1}
                borderRadius={"md"}
                cursor={"pointer"}
              >
                <Text as={"span"} color={"whiteAlpha.700"} mr={1}>
                  {secondsToTwitchTime(message.timestamp)}
                </Text>
                <Text as={"span"} fontWeight={"semibold"} color={message.userColor}>
                  {message.userName}
                </Text>
                <Text as={"span"} mr={1}>
                  :
                </Text>
                <Text as={"span"}>{message.content}</Text>
              </Text>
            ))}
            {!synced && searchTerm === "" && (
              <Button
                onClick={resumeAutoScroll}
                w={"calc(100% - 2rem)"}
                my={0}
                mx={"auto"}
                position={"absolute"}
                left={0}
                right={0}
                bottom={4}
                bg={"blackAlpha.800"}
                _hover={{ bg: "blackAlpha.900" }}
                size={"sm"}
                fontWeight={"normal"}
              >
                Resume auto scroll
              </Button>
            )}
          </>
        ) : (
          <Flex flexDir={"column"} justifyContent={"center"} alignItems={"center"} h={"100%"}>
            {fetchState === "loading" ? (
              <HStack justifyContent={"center"} alignItems={"center"}>
                <Text
                  fontSize={"lg"}
                  fontWeight={"medium"}
                  textAlign={"center"}
                  sx={{ textWrap: "balance" }}
                >
                  Loading...
                </Text>
                <Spinner size="sm" />
              </HStack>
            ) : fetchState === "success" ? (
              <Text fontSize={"md"} textAlign={"center"} sx={{ textWrap: "balance" }}>
                No {messageType} messages to display.
              </Text>
            ) : (
              <>
                <Text fontSize={"md"} textAlign={"center"} sx={{ textWrap: "balance" }}>
                  No {messageType} messages to display.
                </Text>
                <Text
                  color={"red.400"}
                  fontSize={"md"}
                  textAlign={"center"}
                  sx={{ textWrap: "balance" }}
                >
                  Failed to fetch {messageType}.
                </Text>
              </>
            )}
          </Flex>
        )}
      </Box>
    </Flex>
  );
};
