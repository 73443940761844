import {
  Box,
  HStack,
  Kbd,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Badge,
  Code,
  Alert,
  AlertIcon,
  AlertDescription,
} from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DeleteIcon } from "@chakra-ui/icons";
import { ChartLine, MoreOne, Star } from "@icon-park/react";
import KbLayout from "../../components/KnowledgeBase/KbLayout";
import KbButton from "../../components/KnowledgeBase/KbButton";
import LinkedHeading from "../../components/KnowledgeBase/KbLinkedHeading";
import RelatedArticles from "../../components/KnowledgeBase/KbRelated";
import { KBSubPages, RivrLocations } from "../../models/navigation";
import { Text, Link } from "../../components/KnowledgeBase/KbElements";

const KbSearch: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <KbLayout>
      <Box w={"full"} h={"full"}>
        <LinkedHeading as={"h1"} id={"search"} py={2}>
          Search
        </LinkedHeading>
        <LinkedHeading as={"h2"} id={"search-bar"} pt={6} pb={4}>
          Analyzing videos
        </LinkedHeading>
        <Text>
          Located on the{" "}
          <Link onClick={() => navigate(`${RivrLocations.Search}?${searchParams.toString()}`)}>
            Search
          </Link>{" "}
          page, the search bar is where you start analyzing videos. Insert a supported URL and click
          the <KbButton isIconButton={false} text={"Go"} colorScheme={"green"} /> button or press{" "}
          <span>
            <Kbd>Enter</Kbd>
          </span>{" "}
          to start analyzing. After a few moments, your video will appear in the{" "}
          <Link href={"#search-videos"}>Videos</Link> section.
        </Text>
        <LinkedHeading as={"h3"} id={"search-supported-platforms"} pt={6} pb={4}>
          Supported platforms
        </LinkedHeading>
        <TableContainer>
          <Table variant={"simple"} colorScheme={"green"} mb={8}>
            <Thead>
              <Tr>
                <Th>Platform</Th>
                <Th>URL Example</Th>
                <Th isNumeric>Media Type</Th>
              </Tr>
            </Thead>
            <Tbody>
              <Tr>
                <Td>Twitch</Td>
                <Td>
                  <HStack justifyContent={"flex-start"}>
                    <Code>https://twitch.tv/videos/123456789</Code>
                  </HStack>
                </Td>
                <Td isNumeric>
                  <Badge colorScheme={"blue"} size={"md"}>
                    VOD
                  </Badge>
                </Td>
              </Tr>
              {/*               <Tooltip label={"Temporarily disabled"}>
                <Tr opacity={0.33} cursor={"not-allowed"}>
                  <Td>YouTube</Td>
                  <Td>
                    <HStack justifyContent={"flex-start"}>
                      <Code>https://www.youtube.com/watch?v=abcdefgh</Code>
                      <Code>https://youtu.be/abcdefgh</Code>
                    </HStack>
                  </Td>
                  <Td isNumeric>
                    <HStack justifyContent={"flex-end"}>
                      <Badge colorScheme={"blue"} size={"md"}>
                        VOD
                      </Badge>
                    </HStack>
                  </Td>
                </Tr>
              </Tooltip> */}
            </Tbody>
          </Table>
        </TableContainer>
        <Alert status={"warning"} borderRadius={"md"} mb={2}>
          <AlertIcon />
          <AlertDescription>Twitch Highlights are not currently supported.</AlertDescription>
        </Alert>
        <Alert status={"warning"} borderRadius={"md"}>
          <AlertIcon />
          <AlertDescription>
            Analyzing private videos or those requiring sign-in or subscription access (such as
            sub-only videos) is not currently supported.
          </AlertDescription>
        </Alert>
        <LinkedHeading as={"h2"} id={"search-videos"} pt={12} pb={4}>
          Videos
        </LinkedHeading>
        <Text>
          Once analysis begins, it will appear in the Videos section. Here, you can view the length,
          title, publication date, status, and progress of the analysis. When your video has
          finished analyzing, you can access the{" "}
          <Link
            onClick={() =>
              navigate(
                `${RivrLocations.KnowledgeBase}/${KBSubPages.Results}?${searchParams.toString()}`
              )
            }
          >
            results
          </Link>{" "}
          by clicking on the title or thumbnail.
        </Text>
        <Text>
          If the analysis is unsuccessful, an <Badge colorScheme={"red"}>Error</Badge> badge will be
          displayed along with any relevant information in a pop-up notification. If you encounter
          repeated errors, ensure the URL of the video you're attempting to analyze is supported and
          that the video is publicly accessible. If the issue persists, please contact{" "}
          <Link
            onClick={() =>
              navigate(
                `${RivrLocations.KnowledgeBase}/${KBSubPages.Support}?${searchParams.toString()}`
              )
            }
          >
            support
          </Link>
          .
        </Text>
        <Text>
          By clicking the menu{" "}
          <KbButton isIconButton={true} icon={<MoreOne size={"20px"} />} colorScheme={"gray"} />{" "}
          button on a video, you can access its{" "}
          <Link
            onClick={() =>
              navigate(
                `${RivrLocations.KnowledgeBase}/${KBSubPages.Results}?${searchParams.toString()}`
              )
            }
          >
            results
          </Link>{" "}
          or delete it. Deleting a video cannot be undone. You can clear your previously analyzed
          videos by clicking the delete{" "}
          <KbButton isIconButton={true} icon={<DeleteIcon />} colorScheme={"red"} /> button in the
          upper-right of the videos section. This will also make your previous downloads and Moments
          inaccessible. Clearing all of your videos cannot be undone.
        </Text>

        <LinkedHeading as={"h3"} id={"search-limit"} pt={6} pb={4}>
          Active limit
        </LinkedHeading>
        <Text>
          You can have up to two videos actively being analyzed at the same time. The active limit
          is displayed in the top-left corner of the Videos section. Once this limit is reached,
          you'll need to wait for the current videos to finish analyzing before you can start new
          ones.
        </Text>

        <LinkedHeading as={"h3"} id={"search-speed"} pt={6} pb={4}>
          Analyzing speed
        </LinkedHeading>
        <Text>
          The time it takes to analyze a video can vary depending on several factors. Typically, it
          takes between 1-5 minutes per hour of video to complete.
        </Text>
        <Alert status={"warning"} borderRadius={"md"}>
          <AlertIcon />
          <AlertDescription>
            Currently, Rivr does not provide an estimated time to completion.
          </AlertDescription>
        </Alert>

        <Box py={6}>
          <Text textAlign={"right"} fontSize={"sm"} pb={0}>
            <em>
              Last updated on Sept. 25, 2024 by{" "}
              <Link href={"https://x.com/marcfromrivr"} isExternal>
                @MarcFromRivr
              </Link>
            </em>
          </Text>
        </Box>
        <RelatedArticles
          articles={[
            {
              title: "Results",
              description: "How to navigate results and download clips",
              icon: Star,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Results}`,
            },
            {
              title: "The Timeline",
              description: "How to navigate and understand Signals",
              icon: ChartLine,
              link: `${RivrLocations.KnowledgeBase}/${KBSubPages.Timeline}`,
            },
          ]}
        />
      </Box>
    </KbLayout>
  );
};

export default KbSearch;
