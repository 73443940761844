import { RivrMoment, rivrMomentFromQueryResult } from "./moment";

type SearchMetadata = {
  userName: string;
  publishedAt: Date;
  language: string;
  thumbnailUrl: string;
};

export type RivrSearch = {
  id: string;
  url: string;
  createdAt: Date;
  originType: string;
  videoTitle?: string;
  videoType: string;
  videoDuration: string;
  searchProgress: number;
  status: string;
  workflowStatus?: string;
  archivedAt?: Date;
  userMoments: RivrMoment[];
  recommendedMoments: RivrMoment[];
  momentsCount?: number;
  metadata?: SearchMetadata;
};

function parseYTDate(dateStr: string): string {
  const year = dateStr.substring(0, 4);
  const month = dateStr.substring(4, 6);
  const day = dateStr.substring(6, 8);
  return new Date(`${year}-${month}-${day}`).toLocaleDateString("en-US");
}

function transformThumbnailURL(url: string): string {
  return url.replace("%{width}", "480").replace("%{height}", "270");
}

export function getSearchDisplayStatus(search: RivrSearch): string {
  if (search.archivedAt !== undefined) return "archived";
  if (search.workflowStatus !== undefined) return search.workflowStatus;
  if (["in-progress", "error"].includes(search.status)) {
    return search.status === "error" ? search.status : "analyzing";
  }
  return "analyzed";
}

export function searchFromQueryResult(search: any): RivrSearch {
  return {
    id: search.id,
    url: search.url,
    createdAt: new Date(search.created_at),
    originType: search.origin_type,
    videoTitle: search.video_title,
    videoType: search.video_type,
    videoDuration: search.video_duration,
    searchProgress: search.search_progress,
    status: search.status,
    workflowStatus: search.workflow_status ? search.workflow_status : undefined,
    archivedAt: search.archived_at ? new Date(search.archived_at) : undefined,
    userMoments: search.moments
      ? search.moments.map((moment: any) => rivrMomentFromQueryResult(moment, search))
      : [],
    recommendedMoments: search.recommended_moments
      ? search.recommended_moments.map((moment: any) => rivrMomentFromQueryResult(moment, search))
      : [],
    momentsCount: search.moments_aggregate ? search.moments_aggregate.aggregate.count : undefined,
    metadata:
      search.metadata === null
        ? undefined
        : {
            userName:
              search.origin_type === "Youtube"
                ? search.metadata.channel
                : search.metadata.user_name,
            publishedAt: new Date(
              search.origin_type === "Youtube"
                ? parseYTDate(search.metadata.upload_date)
                : search.metadata.published_at
            ),
            language: search.metadata.language || "none",
            thumbnailUrl: transformThumbnailURL(
              search.origin_type === "Youtube"
                ? search.metadata.thumbnail
                : search.metadata.thumbnail_url
            ),
          },
  };
}
