import { useContext, useState } from "react";
import { Alert, AlertDescription, AlertIcon, Box, Flex } from "@chakra-ui/react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useQuery } from "@apollo/client";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

import TourButton from "../components/Tour/TourButton";
import { AccountContext } from "../context/AccountContext";
import { demoUserIds } from "../utils/demoUserIds";
import { Searchbar } from "../components/SearchBar/Searchbar";
import { SearchHistory } from "../components/SearchHistory/SearchHistory";
import EmailCapture from "../components/EmailCapture/EmailCapture";
import { AccessKeysByKindeIdQuery } from "../api/access-key";
import { Loading } from "../components/Loading/Loading";
import NewUserSearchHeader from "../components/Conversion/NewUserSearchHeader";
import ConvertSearchHeader from "../components/Conversion/ConvertSearchHeader";
import { RivrLocations } from "../models/navigation";

const Search = () => {
  const { user, getFlag } = useKindeAuth();
  const { account } = useContext(AccountContext);
  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const isOrgMember = account.memberships.length > 0;

  const isBetaUser = getFlag("beta-user", false).value as boolean;

  const demoUser = demoUserIds.includes(user?.id ?? "") as boolean;

  const [activeKeys, setActiveKeys] = useState<any[]>([]);
  const activeSearchLimit = demoUserIds.includes(user?.id ?? "") ? 10 : 2;
  const [newSearchEventCounter, setNewSearchEventCounter] = useState(0);

  const hasSearchAccess = isAdmin || isBetaUser || account.subscription_status === "active";

  const showCampaignCTA = activeKeys.length !== 0;

  const { data: keysData, loading: keysLoading } = useQuery(AccessKeysByKindeIdQuery, {
    onCompleted(data) {
      if (data.access_key_with_expiration) {
        setActiveKeys(
          data.access_key_with_expiration.filter((key: any) => {
            return new Date(key.expires_at) >= new Date();
          })
        );
      }
    },
    onError({ graphQLErrors, networkError }) {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          console.log("Error:", err.extensions.code);
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    },
    variables: { account_id: account.id },
  });

  const hasCompletedCampaign = keysData?.access_key_with_expiration.length > 0;

  const [activeSearchCount, setActiveSearchCount] = useState(0);

  if (!user || !user.id) return null;

  return isAdmin || !isOrgMember ? (
    <Box h={"100%"} position="relative" w="100%" className="search-page">
      {demoUser && <EmailCapture />}

      <TourButton right={"1.1rem"} bottom={16} />
      <Box w={"100%"} maxW={"1600px"} mx={"auto"} pt={6}>
        {!keysLoading ? (
          <Flex justifyContent={"center"} w={"100%"}>
            {showCampaignCTA ? (
              <Alert status={"success"} borderRadius={"md"} maxW={"fit-content"} mx={4}>
                <AlertIcon />
                <AlertDescription mr={4} sx={{ textWrap: "pretty" }}>
                  You're participating in an ongoing campaign. VODs will appear below shortly after
                  streaming.
                </AlertDescription>
              </Alert>
            ) : null}
          </Flex>
        ) : (
          <Loading variant={"small"} />
        )}

        <Flex py={6} justify={"center"} w={"100%"}>
          {hasSearchAccess ? (
            <Searchbar
              activeSearchCount={activeSearchCount}
              activeSearchLimit={activeSearchLimit}
              searchCreated={() => setNewSearchEventCounter((value) => value + 1)}
            />
          ) : !hasCompletedCampaign ? (
            <NewUserSearchHeader />
          ) : (
            <ConvertSearchHeader />
          )}
        </Flex>

        <SearchHistory
          newSearchEventCounter={newSearchEventCounter}
          onActiveSearchCountChange={setActiveSearchCount}
          activeSearchLimit={activeSearchLimit}
        />
      </Box>
    </Box>
  ) : (
    <Navigate replace to={RivrLocations.Campaigns} />
  );
};
export default Search;
