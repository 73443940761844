import { useState, useEffect, useRef, useMemo, useContext } from "react";
import {
  Button,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Tooltip,
  SimpleGrid,
  Center,
  Box,
  Divider,
  Text,
  HStack,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Badge,
  MenuGroup,
  FormControl,
  FormHelperText,
  MenuDivider,
} from "@chakra-ui/react";
import { DeleteIcon, SearchIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { useMutation, useQuery } from "@apollo/client";
import {
  DeleteAllSearchHistoryMutation,
  SearchHistoryQuery,
  DeleteSearchMutation,
} from "../../api/search";
import { usePagination } from "../../hooks/usePagination";
import { getSearchDisplayStatus, RivrSearch, searchFromQueryResult } from "../../models/rivrSearch";
import { SearchHistoryItem } from "./SearchHistoryItem/SearchHistoryItem";
import { Close, Filter, SortThree } from "@icon-park/react";
import Cookies from "js-cookie";
import { durationToSeconds, offsetDate } from "../../utils/time";
import { AccountContext } from "../../context/AccountContext";

interface Props {
  newSearchEventCounter: number;
  onActiveSearchCountChange: (count: number) => void;
  activeSearchLimit: number;
}

export const SearchHistory = ({
  newSearchEventCounter,
  onActiveSearchCountChange,
  activeSearchLimit,
}: Props) => {
  const DEFAULT_STATUS_SELECTION = ["analyzing", "analyzed", "reviewing", "completed", "error"];
  const isAdmin = Cookies.get("xHasuraRole") === "admin";
  const { account } = useContext(AccountContext);
  const historyLimit = parseInt(process.env.REACT_APP_SEARCH_HISTORY_MAX_LIMIT || "50");
  const [searchList, setSearchList] = useState<RivrSearch[]>([]);
  const [activeSearchCount, setActiveSearchCount] = useState<number>(0);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const onCloseDialog = () => setIsConfirmDialogOpen(false);
  const cancelRef = useRef<HTMLButtonElement>(null);
  const [filterTerm, setFilterTerm] = useState("");
  const [dateFilterStart, setDateFilterStart] = useState("");
  const [dateFilterEnd, setDateFilterEnd] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState(DEFAULT_STATUS_SELECTION);
  const [sortDirection, setSortDirection] = useState("desc");
  const [sortMethod, setSortMethod] = useState("published");
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [selectedSearches, setSelectedSearches] = useState<Set<string>>(new Set());

  const handleDateFilterStart = (date: string) => {
    setDateFilterStart(date);
  };

  const handleDateFilterEnd = (date: string) => {
    setDateFilterEnd(date);
  };

  const handleStatusSelect = (selection: string | string[]) => {
    typeof selection === "string"
      ? setSelectedStatuses([selection])
      : setSelectedStatuses(
          selection.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase());
          })
        );
  };

  const handleSortMethod = (option: string | string[]) => {
    typeof option === "string" ? setSortMethod(option) : setSortMethod(option[0]);
  };

  const handleSortDirection = (direction: string | string[]) => {
    typeof direction === "string" ? setSortDirection(direction) : setSortDirection(direction[0]);
  };

  const { refetch: getSearches } = useQuery(SearchHistoryQuery, {
    onCompleted(data) {
      const activeSearches = data.search.filter((s: any) => s.status === "in-progress");
      setActiveSearchCount(activeSearches.length);
      setSearchList(data.search.map((s: any) => searchFromQueryResult(s)));
    },
    onError({ graphQLErrors, networkError }) {
      console.log("GetDataReceived ERROR: ", graphQLErrors, networkError);
    },
    variables: { user_id: account.kinde_id, limit: historyLimit },
    pollInterval: parseInt(process.env.REACT_APP_ACTIVE_SEARCHES_POLL_TIME || "10000"),
  });

  const [deleteAllSearchHistoryAPI, { loading: isDeleteAllLoading }] = useMutation(
    DeleteAllSearchHistoryMutation,
    {
      onCompleted() {
        getSearches();
        setIsConfirmDialogOpen(false);
      },
      onError({ graphQLErrors, networkError }) {
        if (graphQLErrors) {
          for (const err of graphQLErrors) {
            console.log("Error:", err.extensions.code);
          }
        }
        if (networkError) {
          console.log(`[Network error]: ${networkError}`);
        }
      },
    }
  );

  const [deleteSearchAPI] = useMutation(DeleteSearchMutation, {
    onCompleted() {
      getSearches();
      setIsSelectionMode(false);
      setSelectedSearches(new Set());
    },
    onError({ graphQLErrors, networkError }) {
      console.log("Error deleting searches:", graphQLErrors, networkError);
    },
  });

  const deleteSelectedSearches = () => {
    selectedSearches.forEach((id) => {
      deleteSearchAPI({ variables: { id, deleted_at: new Date() } });
    });
  };

  useEffect(() => {
    getSearches();
  }, [newSearchEventCounter]);

  useEffect(() => {
    onActiveSearchCountChange(activeSearchCount);
  }, [activeSearchCount, onActiveSearchCountChange]);

  const sortSearchList = useMemo(() => {
    return (a: RivrSearch, b: RivrSearch) => {
      // Sort archived videos to the end of the list
      if (isAdmin && a.archivedAt !== undefined && b.archivedAt === undefined) {
        return 1;
      } else if (isAdmin && a.archivedAt === undefined && b.archivedAt !== undefined) {
        return -1;
      }

      // Sort new search skeletons to the front of the list
      if (!a.metadata) {
        return -1;
      } else if (!b.metadata) {
        return 1;
      }

      if (sortMethod === "status") {
        const statusOrder: { [key: string]: number } = {
          "in-progress": 1,
          stopped: 2,
          reviewing: 3,
          completed: 4,
          error: 5,
        };
        const orderA = statusOrder[a.workflowStatus === undefined ? a.status : a.workflowStatus];
        const orderB = statusOrder[b.workflowStatus === undefined ? b.status : b.workflowStatus];

        // Sort descending by date published if status is the same
        if ((orderA || 6) === (orderB || 6)) {
          // Put videos missing metadata (i.e. publishing date) at the end
          return b.metadata.publishedAt.valueOf() - a.metadata.publishedAt.valueOf();
        } else {
          return sortDirection === "asc"
            ? (orderA || 6) - (orderB || 6)
            : (orderB || 6) - (orderA || 6);
        }
      }
      if (sortMethod === "published") {
        // Sort any videos missing metadata (i.e. publishing date) to the end of the list
        return sortDirection === "asc"
          ? a.metadata.publishedAt.valueOf() - b.metadata.publishedAt.valueOf()
          : b.metadata.publishedAt.valueOf() - a.metadata.publishedAt.valueOf();
      }
      if (sortMethod === "title") {
        if (
          a.videoTitle === b.videoTitle ||
          a.videoTitle === undefined ||
          b.videoTitle === undefined
        ) {
          return b.metadata.publishedAt.valueOf() - a.metadata.publishedAt.valueOf();
        } else {
          return sortDirection === "asc"
            ? a.videoTitle.toLowerCase().localeCompare(b.videoTitle.toLowerCase())
            : b.videoTitle.toLowerCase().localeCompare(a.videoTitle.toLowerCase());
        }
      }
      if (sortMethod === "user") {
        if (
          a.momentsCount === b.momentsCount ||
          a.momentsCount === undefined ||
          b.momentsCount === undefined
        ) {
          return b.metadata.publishedAt.valueOf() - a.metadata.publishedAt.valueOf();
        } else {
          return sortDirection === "asc"
            ? a.momentsCount - b.momentsCount
            : b.momentsCount - a.momentsCount;
        }
      }
      if (sortMethod === "duration") {
        if (durationToSeconds(a.videoDuration) === durationToSeconds(b.videoDuration)) {
          return b.metadata.publishedAt.valueOf() - a.metadata.publishedAt.valueOf();
        } else {
          return sortDirection === "asc"
            ? durationToSeconds(a.videoDuration) - durationToSeconds(b.videoDuration)
            : durationToSeconds(b.videoDuration) - durationToSeconds(a.videoDuration);
        }
      }
      return 0;
    };
  }, [sortMethod, sortDirection]);

  const filterSearchList = useMemo(() => {
    return (item: RivrSearch) => {
      if (item.metadata === undefined && item.status === "in-progress") {
        return true; // make sure new search skeletons are visible
      }
      if (selectedStatuses.includes(getSearchDisplayStatus(item)) === false) return false;
      const filterStartDateUTC = offsetDate(dateFilterStart);
      if (
        dateFilterStart &&
        item.metadata &&
        item.metadata.publishedAt.valueOf() >= filterStartDateUTC.valueOf() === false
      )
        return false;
      const filterEndDateUTC = offsetDate(dateFilterEnd);
      filterEndDateUTC.setDate(filterEndDateUTC.getDate() + 1); // make the end date inclusive
      if (
        dateFilterEnd &&
        item.metadata &&
        item.metadata.publishedAt.valueOf() < filterEndDateUTC.valueOf() === false
      )
        return false;
      if (
        item.videoTitle &&
        item.videoTitle.toLowerCase().includes(filterTerm.toLowerCase()) === false
      )
        return false;
      return true;
    };
  }, [filterTerm, dateFilterStart, dateFilterEnd, selectedStatuses]);

  const resetSearchFilters = () => {
    setDateFilterStart("");
    setDateFilterEnd("");
    setSelectedStatuses(DEFAULT_STATUS_SELECTION);
  };

  const {
    elements,
    totalElements,
    page,
    totalPages,
    nextPage,
    previousPage,
    hasNextPage,
    hasPreviousPage,
  } = usePagination(searchList, sortSearchList, filterSearchList, 15);

  return (
    <>
      <Center py={12} px={6}>
        <Box w="100%">
          <HStack justifyContent={"space-between"} alignItems={"center"} py={2}>
            <HStack>
              <Text fontSize="lg" fontWeight="medium">
                Videos
              </Text>
              <Flex display={activeSearchLimit === 10 ? "none" : "flex"}>
                <Tooltip label="Videos that can be analyzed at once">
                  <Badge
                    fontSize="md"
                    borderRadius={"full"}
                    py={0.5}
                    px={3}
                    colorScheme={
                      activeSearchCount >= activeSearchLimit
                        ? "red"
                        : activeSearchCount === activeSearchLimit - 1
                        ? "yellow"
                        : "gray"
                    }
                  >
                    <Text fontWeight={"semibold"} cursor={"default"}>
                      {activeSearchCount} / {activeSearchLimit}
                    </Text>
                  </Badge>
                </Tooltip>
              </Flex>
            </HStack>
            <HStack>
              <Flex>
                <Box>
                  <InputGroup size={"sm"}>
                    <InputLeftElement pointerEvents="none">
                      <SearchIcon pointerEvents="none" />
                    </InputLeftElement>
                    <Input
                      className={"amp-unmask"}
                      size="sm"
                      borderRadius={"md"}
                      value={filterTerm}
                      onChange={(e) => setFilterTerm(e.target.value)}
                      placeholder="Filter by title"
                      variant={"filled"}
                    />
                  </InputGroup>
                </Box>
              </Flex>
              <Box>
                <Menu closeOnSelect={false} placement="bottom-end">
                  <MenuButton
                    as={IconButton}
                    colorScheme="gray"
                    icon={<Filter theme="filled" />}
                    size={"sm"}
                  />
                  <MenuList
                    zIndex={2}
                    maxH={"60vh"}
                    overflowY={"auto"}
                    sx={{ scrollbarWidth: "thin" }}
                  >
                    <MenuGroup title="Date published">
                      <HStack px={4} pb={2}>
                        <FormControl>
                          <Input
                            className={"amp-unmask"}
                            type={"date"}
                            size={"sm"}
                            borderRadius={"md"}
                            value={dateFilterStart}
                            onChange={(e) => handleDateFilterStart(e.target.value)}
                          />
                          <FormHelperText>From</FormHelperText>
                        </FormControl>
                        <FormControl>
                          <Input
                            className={"amp-unmask"}
                            type={"date"}
                            size={"sm"}
                            borderRadius={"md"}
                            value={dateFilterEnd}
                            onChange={(e) => handleDateFilterEnd(e.target.value)}
                          />
                          <FormHelperText>To</FormHelperText>
                        </FormControl>
                      </HStack>
                    </MenuGroup>
                    <MenuDivider />
                    <MenuOptionGroup
                      value={selectedStatuses}
                      title="Status"
                      type="checkbox"
                      onChange={(values) => handleStatusSelect(values)}
                    >
                      <MenuItemOption value="analyzing">Analyzing</MenuItemOption>
                      <MenuItemOption value="analyzed">Analyzed</MenuItemOption>
                      {isAdmin && <MenuItemOption value="reviewing">Reviewing</MenuItemOption>}
                      {isAdmin && <MenuItemOption value="completed">Completed</MenuItemOption>}
                      <MenuItemOption value="error">Error</MenuItemOption>
                      {isAdmin && <MenuItemOption value="archived">Archived</MenuItemOption>}
                    </MenuOptionGroup>
                    <MenuDivider />
                    <MenuGroup>
                      <Button
                        size={"sm"}
                        colorScheme={"gray"}
                        variant={"ghost"}
                        onClick={resetSearchFilters}
                        mx={3}
                        fontWeight={"normal"}
                      >
                        Reset filters
                      </Button>
                    </MenuGroup>
                  </MenuList>
                </Menu>
              </Box>
              <Box>
                <Menu closeOnSelect={false}>
                  <MenuButton as={IconButton} icon={<SortThree />} size={"sm"} />
                  <MenuList minWidth="260px" zIndex={2}>
                    <MenuOptionGroup
                      value={sortDirection}
                      title="Order"
                      type="radio"
                      onChange={handleSortDirection}
                    >
                      <MenuItemOption value="asc">Ascending</MenuItemOption>
                      <MenuItemOption value="desc">Descending</MenuItemOption>
                    </MenuOptionGroup>
                    <MenuDivider />
                    <MenuOptionGroup
                      value={sortMethod}
                      title="Sort by"
                      type="radio"
                      onChange={handleSortMethod}
                    >
                      <MenuItemOption value="published">Date published</MenuItemOption>
                      <MenuItemOption value="status">Status</MenuItemOption>
                      <MenuItemOption value="title">Title</MenuItemOption>
                      <MenuItemOption value="user">Moments</MenuItemOption>
                      <MenuItemOption value="duration">Duration</MenuItemOption>
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </Box>
              <Tooltip
                label={isSelectionMode ? "Delete selected" : "Delete videos"}
                placement={"bottom-end"}
              >
                <Box pos={"relative"}>
                  <IconButton
                    aria-label="Delete videos"
                    onClick={() => {
                      if (isSelectionMode) {
                        setIsConfirmDialogOpen(true);
                      } else {
                        setIsSelectionMode(true);
                      }
                    }}
                    size={"sm"}
                    colorScheme={isSelectionMode ? "green" : "gray"}
                    isLoading={isDeleteAllLoading}
                    icon={<DeleteIcon />}
                    isDisabled={selectedSearches.size === 0 && isSelectionMode}
                  />
                  {isSelectionMode && (
                    <Badge
                      bg={"gray.600"}
                      borderRadius={"full"}
                      pos={"absolute"}
                      top={-1.5}
                      right={-1.5}
                      fontSize="0.65em"
                      minWidth={4}
                      textAlign={"center"}
                      zIndex={1}
                      variant={"solid"}
                      color={"gray.50"}
                    >
                      {selectedSearches.size}
                    </Badge>
                  )}
                </Box>
              </Tooltip>
              {isSelectionMode && (
                <Tooltip label={"Cancel selection"} placement={"bottom-end"}>
                  <IconButton
                    size={"sm"}
                    aria-label="Cancel selection for delete"
                    colorScheme="red"
                    icon={<Close />}
                    onClick={() => {
                      setIsSelectionMode(false);
                      setSelectedSearches(new Set());
                    }}
                  />
                </Tooltip>
              )}
            </HStack>
          </HStack>

          <Divider mb={4} />
          {elements.length > 0 ? (
            <SimpleGrid columns={{ md: 2, lg: 3, xl: 4, "3xl": 5, "4xl": 5 }} spacing={4} my={4}>
              {elements.map((item) => (
                <SearchHistoryItem
                  key={item.id}
                  search={item}
                  onDelete={getSearches}
                  isSelectionMode={isSelectionMode}
                  isSelected={selectedSearches.has(item.id)}
                  onSelect={(id: string, selected: boolean) => {
                    setSelectedSearches((prev) => {
                      const newSet = new Set(prev);
                      if (selected) {
                        newSet.add(id);
                      } else {
                        newSet.delete(id);
                      }
                      return newSet;
                    });
                  }}
                />
              ))}
            </SimpleGrid>
          ) : (
            <Center pb="4">
              <Text mt="12" color={"gray.300"}>
                {searchList.length === 0
                  ? "Videos being analyzed will appear here"
                  : "No videos match your filter settings"}
              </Text>
            </Center>
          )}
          {elements.length > 0 && (
            <HStack justifyContent={"space-between"} spacing={2}>
              <Box>
                <Text fontSize={"sm"} color={"gray.400"}>
                  Showing {totalElements} {totalElements === 1 ? "video" : "videos"}
                </Text>
              </Box>

              <HStack>
                <Text
                  fontSize={"sm"}
                  color={"gray.400"}
                  pr={2}
                >{`Page ${page} of ${totalPages}`}</Text>
                <IconButton
                  size="sm"
                  variant={"outline"}
                  aria-label="Previous history page"
                  icon={<ChevronLeftIcon />}
                  onClick={previousPage}
                  disabled={!hasPreviousPage}
                />
                <IconButton
                  size="sm"
                  variant={"outline"}
                  aria-label="Next history page"
                  icon={<ChevronRightIcon />}
                  onClick={nextPage}
                  disabled={!hasNextPage}
                />
              </HStack>
            </HStack>
          )}
        </Box>
      </Center>
      <AlertDialog
        isOpen={isConfirmDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onCloseDialog}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete selected videos
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to delete the selected videos and their Moments? This cannot be
              undone.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onCloseDialog} colorScheme="gray" variant="ghost">
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  deleteSelectedSearches();
                  onCloseDialog();
                }}
                ml={2}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
