import { useState, useEffect, useRef, useMemo } from "react";
import { Flex, IconButton, Tooltip, VStack, useToast } from "@chakra-ui/react";
import { useQuery, useMutation, useSubscription } from "@apollo/client";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import _ from "lodash";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

import { MomentPanel } from "../components/MomentPanel/MomentPanel";
import { SearchByIDQuery, StopSearchMutation } from "../api/search";
import { UpsertMomentMutation, UpdateMomentMutation, DeleteMomentsMutation } from "../api/moment";
import { MediaPanel } from "../components/MediaPanel/MediaPanel";
import {
  Moment,
  RecommendedMoment,
  SerializableMoment,
  momentFromJSON,
  momentFromRecommendedMoment,
  recommendedMomentFromEvent,
} from "../models/moment";
import { MomentFilter } from "../components/MomentPanel/FilterPopover/FilterPopover";
import "./results.css";

import TourButton from "../components/Tour/TourButton";
import {
  RecommendedMomentsBySearchIDSubscription,
  ToggleMomentMutation,
} from "../api/recommended-moment";
import { SelectedMoment } from "../models/selectedMoment";
import { useTrigger } from "../hooks/useTrigger";
import { ChatPanel } from "../components/ChatPanel/ChatPanel";
import { SignalsBySearchIDSubscription } from "../api/signal";
import { signals, signalMap, annotations as signalAnnotations } from "../models/signals";
import { secondsToDate, durationToSeconds } from "../utils/time";
import { getPeakInInterval, simplify } from "../utils/signal";
import { dedupeMoments } from "../utils/moment";
import { ResultsURLParams, RivrLocations } from "../models/navigation";

type Signal = {
  from_seconds: number;
  x_values: string;
  y_values: string;
  title: string;
};

const Results = () => {
  const minRecommendedMoments = 30;

  const { videoID } = useParams() as { videoID: string };

  const [searchParams] = useSearchParams();
  const momentId = searchParams.get(ResultsURLParams.SelectedMoment);
  const mentionTimestamp = searchParams.get(ResultsURLParams.SelectedTimestamp);

  const toast = useToast();
  const [videoInfo, setVideoInfo] = useState<any>(null);
  const [moments, setMoments] = useState<Moment[]>([]);
  const activeMoments = moments.filter((moment) => !moment.deleted);
  const [recommendedMoments, setRecommendedMoments] = useState<RecommendedMoment[]>([]);
  const [recommendedAmount, setRecommendedAmount] = useState(0);
  const filteredRecommendedMoments = recommendedMoments.slice(
    0,
    minRecommendedMoments + recommendedAmount * (recommendedMoments.length - minRecommendedMoments)
  );
  const [isLoopingLoading, setIsLoopingLoading] = useState(false);
  const [focusedItem, setFocusedItem] = useState<SelectedMoment | null>(null);
  const lastAcceptedMoment = useRef<string | null>(null);
  const selectedMomentIndex =
    focusedItem === null || focusedItem.kind === "temporary"
      ? -1
      : focusedItem.kind === "user"
      ? activeMoments.findIndex((item) => item.id === focusedItem.id)
      : recommendedMoments.findIndex((item) => item.id === focusedItem.id);
  const [tempMoment, setTempMoment] = useState<Pick<Moment, "id" | "start_time" | "end_time">>({
    id: "temp",
    start_time: 0,
    end_time: 0,
  });
  const selectedMoment =
    focusedItem?.kind === "temporary"
      ? tempMoment
      : selectedMomentIndex === -1
      ? null
      : focusedItem?.kind === "user"
      ? activeMoments[selectedMomentIndex]
      : recommendedMoments[selectedMomentIndex];
  const localIDs = useRef<string[]>([]);
  const [defaultMomentDuration, setDefaultMomentDuration] = useState(60);
  const [filters, setFilters] = useState<MomentFilter[]>([]);
  const filteredMoments = useMemo(
    () => activeMoments.filter((moment) => filters.every((filter) => matchFilter(moment, filter))),
    [activeMoments, filters]
  );
  const [upsertMomentMutation] = useMutation(UpsertMomentMutation, {
    onCompleted(data) {
      localIDs.current = localIDs.current.filter((id) => id !== data.insert_moment_one.id);
    },
  });
  const [updateMomentMutation] = useMutation(UpdateMomentMutation);
  const [deleteMomentsMutation] = useMutation(DeleteMomentsMutation);
  const [toggleMomentMutation] = useMutation(ToggleMomentMutation);
  const navigate = useNavigate();
  const [momentTabIndex, setMomentTabIndex] = useState(0);
  const [pausePlayer, setPausePlayer] = useState({ pause: false });
  const [playTime, setPlayTime] = useState(0);
  const [seekTime, setSeekTime] = useState<{ seconds: number } | null>(null);
  const [looping, setLooping] = useState(false);
  const [isChatReady, setIsChatReady] = useState(false);
  const [signalData, setSignalData] = useState<Map<string, { x: Date[]; y: number[] }>>(new Map());
  const [xIntervals, setXIntervals] = useState<number[]>(() => signals.map(() => 0));
  const [lastPoints, setLastPoints] = useState<(number | null)[]>(() => signals.map(() => null));
  const [waitingData, setWaitingData] = useState<Signal[][]>(() => signals.map(() => []));
  const [maxValues, setMaxValues] = useState<Record<string, number>>({});

  const signalSub = useSubscription(SignalsBySearchIDSubscription, {
    variables: { id: videoID },
  });

  useTrigger(() => {
    if (signalSub.data && signalSub.data.signals_stream && signalSub.data.signals_stream.length) {
      const rawSignals = new Map<string, Signal[]>();
      for (const item of signalSub.data.signals_stream as Signal[]) {
        const rawSignal = rawSignals.get(item.title);
        if (rawSignal) rawSignal.push(item);
        else rawSignals.set(item.title, [item]);
      }

      const parsedSignals = new Map(signalData);
      const newXIntervals = [...xIntervals];
      const newLastPoints = [...lastPoints];
      const newWaitingData = [...waitingData];
      const newMaxValues = { ...maxValues };
      let newData = false;

      rawSignals.forEach((value, key) => {
        const signal = signalMap.get(key);
        if (!signal) return;

        let xInterval = xIntervals[signal.index];
        let lastPoint = lastPoints[signal.index];
        const unprocessed = [...waitingData[signal.index], ...value];
        unprocessed.sort((a, b) => a.from_seconds - b.from_seconds);

        const newTraceX: number[] = [];
        const newTraceY: number[] = [];
        let i = 0;
        for (; i < unprocessed.length; ++i) {
          const item = unprocessed[i];
          const xValues = JSON.parse(item.x_values);
          if (xValues.length === 0) continue;

          if (xInterval === 0 && xValues.length > 1) {
            xInterval = xValues[1] - xValues[0];
            newXIntervals[signal.index] = xInterval;
          }

          const roundingError = 0.05;
          let start = 0;
          if (lastPoint !== null) {
            if (xValues[0] - xInterval - lastPoint > roundingError) break;
            if (xValues[xValues.length - 1] - lastPoint < roundingError) continue;
            while (start < xValues.length - 1 && xValues[start] - lastPoint < roundingError)
              ++start;
          }

          newTraceX.push(...xValues.slice(start));
          newTraceY.push(...JSON.parse(item.y_values).slice(start));
          lastPoint = xValues[xValues.length - 1];
        }
        newWaitingData[signal.index] = unprocessed.slice(i);

        if (newTraceX.length) {
          newLastPoints[signal.index] = lastPoint;
          const existingSignals = parsedSignals.get(key) || { x: [], y: [] };
          let scaledY = newTraceY;
          if (signal.rescale) {
            const maxValue = maxValues[key] || 0;
            let newMax = _.max(newTraceY) as number;
            newMax = _.max([maxValue, newMax]) as number;
            if (newMax > 0) {
              scaledY = newTraceY.map((y) => y / newMax);
              if (newMax > maxValue && maxValue > 0) {
                existingSignals.y = existingSignals.y.map((y) => (y * maxValue) / newMax);
              }
            }
            newMaxValues[key] = newMax;
          }
          const deadzonedY = scaledY.map((val) => (Math.abs(val) < signal.deadzone ? 0 : val));
          const [simplifiedX, simplifiedY] = simplify(newTraceX, deadzonedY);
          const timeX = simplifiedX.map((val) => secondsToDate(val));
          parsedSignals.set(key, {
            x: [...existingSignals.x, ...timeX],
            y: [...existingSignals.y, ...simplifiedY],
          });
          newData = true;
          if (
            key === "Chat intensity" &&
            videoInfo &&
            durationToSeconds(videoInfo.video_duration) - simplifiedX[simplifiedX.length - 1] <= 120
          )
            onChatDone();
        }
      });

      setXIntervals(newXIntervals);
      setWaitingData(newWaitingData);
      if (newData) {
        setSignalData(parsedSignals);
        setLastPoints(newLastPoints);
        setMaxValues(newMaxValues);
      }
    }
  }, [signalSub.data]);

  const recommendedSub = useSubscription(RecommendedMomentsBySearchIDSubscription, {
    variables: { id: videoID },
  });
  useTrigger(() => {
    if (recommendedSub.data && recommendedSub.data.recommended_moment_stream) {
      const recommendedEvents = recommendedSub.data.recommended_moment_stream;
      const newRecommendedMoments: RecommendedMoment[] = recommendedEvents
        .map((event: any) => recommendedMomentFromEvent(event, videoID))
        .filter((maybeMoment: RecommendedMoment | null) => maybeMoment);

      const allRecommendedMoments = [...recommendedMoments, ...newRecommendedMoments];
      const uniqueMoments = dedupeMoments(allRecommendedMoments);
      uniqueMoments.sort((a, b) => b.level - a.level || a.start_time - b.start_time);

      if (momentId && recommendedMoments.length === 0) {
        const urlMoment = uniqueMoments.find((m) => m.id === momentId);
        if (urlMoment) setFocusedItem({ kind: "recommended", id: momentId });
      }

      setRecommendedMoments(uniqueMoments);
    }
  }, [recommendedSub.data]);

  const annotateMoment = (moment: Moment): Moment => {
    const annotations: Record<string, number> = {};
    for (const { name, signal, negative } of signalAnnotations) {
      const annotation = getPeakInInterval(
        signalData.get(signal) || { x: [], y: [] },
        moment.start_time,
        moment.end_time,
        negative ? "min" : "max"
      );

      if (annotation !== undefined)
        annotations[name] = +(annotation * (negative ? -1 : 1)).toFixed(3);
    }
    return { ...moment, annotations };
  };

  function matchFilter(moment: Moment, filter: MomentFilter) {
    if (filter.mode === "all") return filter.tags.every((tag) => moment.tags.includes(tag));
    return filter.tags.some((tag) => moment.tags.includes(tag));
  }

  const saveMoment = (moment: Moment) => {
    if (localIDs.current.includes(moment.id))
      upsertMomentMutation({ variables: { object: moment } });
    else {
      const momentUpdate: any = { ...moment };
      delete momentUpdate.search_id;
      updateMomentMutation({ variables: { id: moment.id, object: momentUpdate } });
    }
  };

  useTrigger(() => {
    if (focusedItem?.kind === "user" && momentTabIndex !== 0) setMomentTabIndex(0);
    if (focusedItem?.kind === "recommended" && momentTabIndex !== 1) setMomentTabIndex(1);
  }, [focusedItem]);

  useTrigger(() => {
    if (focusedItem?.kind === "recommended" && momentTabIndex !== 1) setFocusedItem(null);
  }, [momentTabIndex]);

  useQuery(SearchByIDQuery, {
    onCompleted(data) {
      if (data && data.search_by_pk) {
        const searchItem = data.search_by_pk;

        const serverMoments: Moment[] = searchItem.moments.map((moment: any) => {
          delete moment.__typename;
          return momentFromJSON(moment);
        });
        const localMomentsJSON = localStorage.getItem(`searchMoments.${videoID}`);
        const localMoments: Moment[] = localMomentsJSON
          ? JSON.parse(localMomentsJSON).map((moment: SerializableMoment) => momentFromJSON(moment))
          : [];
        const updateIDs: string[] = [];
        const updateMoments: Moment[] = [];
        localMoments.forEach((moment) => {
          const serverMoment = serverMoments.find((item) => item.id === moment.id);
          if (!serverMoment || serverMoment.updated_at < moment.updated_at) {
            updateIDs.push(moment.id);
            updateMoments.push(moment);
            if (!serverMoment) localIDs.current.push(moment.id);
          }
        });
        const mergedMoments = serverMoments
          .filter((moment) => !updateIDs.includes(moment.id))
          .concat(localMoments.filter((moment) => updateIDs.includes(moment.id)));
        setMoments(mergedMoments);
        updateMoments.forEach((moment) => saveMoment(moment));

        if (momentId) {
          const urlMoment = mergedMoments.find((m) => m.id === momentId);
          if (urlMoment) setFocusedItem({ kind: "user", id: momentId });
        } else if (mentionTimestamp) {
          const ts = parseInt(mentionTimestamp);
          setTempMoment({
            ...tempMoment,
            start_time: ts - 30,
            end_time: ts + 30,
          });
          setFocusedItem({ kind: "temporary" });
          setSeekTime({ seconds: ts });
        }

        setIsLoopingLoading(false);
        setVideoInfo(searchItem);
      } else {
        navigate(RivrLocations.AccessDenied, { replace: true });
      }
    },
    onError({ graphQLErrors, networkError }) {
      console.log("GetSearchByIDs ERROR: ", graphQLErrors, networkError);
    },
    variables: { id: videoID },
    pollInterval: videoInfo && videoInfo.status === "in-progress" ? 5000 : 0,
  });

  const [stopSearchAPI] = useMutation(StopSearchMutation, {
    onCompleted() {
      return;
    },
    onError({ graphQLErrors, networkError }) {
      showToast(videoID, "An error occured, Please try again later!", "error");
      console.log("stopSearchAPI ERROR: ", graphQLErrors, networkError);
    },
  });

  const showToast = (id: string, description: any, status: any) => {
    if (!toast.isActive(id)) {
      toast({
        id: id,
        title: description,
        status: status,
        duration: 7000,
        isClosable: true,
        variant: "solid",
        position: "bottom",
      });
    }
  };

  const stopSearch = () => {
    setIsLoopingLoading(true);
    stopSearchAPI({ variables: { id: videoID } });
  };

  const addMoment = (moment: Moment) => {
    const annotatedMoment = annotateMoment(moment);
    setMoments([...moments, annotatedMoment]);
    setFocusedItem({ kind: "user", id: moment.id });
    localIDs.current.push(moment.id);
    upsertMomentMutation({ variables: { object: annotatedMoment } });
  };

  const acceptMoments = (recommended: RecommendedMoment[]) => {
    const newMoments = recommended.map((m) => annotateMoment(momentFromRecommendedMoment(m)));
    setMoments([...moments, ...newMoments]);
    localIDs.current.push(...newMoments.map((moment) => moment.id));
    newMoments.forEach((moment) => upsertMomentMutation({ variables: { object: moment } }));
    lastAcceptedMoment.current = newMoments.length === 1 ? newMoments[0].id : null;
  };

  const focusLastMoment = () => {
    setMomentTabIndex(0);
    setFocusedItem(
      lastAcceptedMoment.current === null ? null : { kind: "user", id: lastAcceptedMoment.current }
    );
  };

  const deleteMoments = (ids: string[]) => {
    const now = new Date();
    const newMoments = moments.map((moment) =>
      ids.includes(moment.id) ? { ...moment, deleted: true, updated_at: now } : moment
    );
    setMoments(newMoments);

    const initialState: { local: string[]; remote: string[] } = { local: [], remote: [] };
    const { local, remote } = ids.reduce((idMap, id) => {
      idMap[localIDs.current.includes(id) ? "local" : "remote"].push(id);
      return idMap;
    }, initialState);

    local.forEach((id) => {
      const moment = newMoments.find((item) => item.id === id);
      if (moment) saveMoment(moment);
    });
    if (remote.length) deleteMomentsMutation({ variables: { ids: remote, updated_at: now } });
  };

  const updateTempMoment = (update: Pick<Moment, "start_time" | "end_time">) => {
    setTempMoment({ ...tempMoment, ...update });
    setFocusedItem({ kind: "temporary" });
  };

  const updateSelectedMoment = (update: Pick<Moment, "start_time" | "end_time">) => {
    setDefaultMomentDuration(update.end_time - update.start_time);
    if (selectedMoment) {
      if (selectedMoment.id === "temp") setTempMoment({ ...tempMoment, ...update });
      else updateMoment(selectedMoment as Moment, update);
    }
  };

  const updateMoment = (moment: Moment, update: Partial<Moment>) => {
    const newMoment = annotateMoment({ ...moment, ...update, updated_at: new Date() });
    setMoments(moments.map((item) => (item.id === moment.id ? newMoment : item)));
    saveMoment(newMoment);
  };

  useEffect(() => {
    localStorage.setItem(`searchMoments.${videoID}`, JSON.stringify(moments));
  }, [moments]);

  const addFilter = (filter: MomentFilter) => {
    setFilters([filter]);
    if (focusedItem?.kind === "user" && !matchFilter(selectedMoment as Moment, filter))
      setFocusedItem(null);
  };

  const removeFilter = (filter: MomentFilter) => {
    setFilters(filters.filter((f) => f !== filter));
  };

  const toggleMoment = (id: string) => {
    const moment = recommendedMoments.find((moment) => moment.id === id);
    if (!moment) return;
    setRecommendedMoments(
      recommendedMoments.map((m) => (m.id === id ? { ...m, rejected: !m.rejected } : m))
    );
    toggleMomentMutation({ variables: { id: id, rejected: !moment.rejected } });
  };

  const handleHypeGraphSeek = (seconds: number) => {
    setSeekTime({ seconds });
    if (
      !selectedMoment ||
      seconds < selectedMoment.start_time ||
      seconds > selectedMoment.end_time
    ) {
      const startTime = Math.max(0, Math.round(seconds - defaultMomentDuration / 2));
      updateTempMoment({
        start_time: startTime,
        end_time: startTime + defaultMomentDuration,
      });
    }
  };

  useTrigger(() => {
    if (selectedMoment) setSeekTime({ seconds: selectedMoment.start_time });
  }, [selectedMoment]);

  useTrigger(() => {
    if (
      looping &&
      selectedMoment &&
      (playTime < selectedMoment.start_time || playTime >= selectedMoment.end_time)
    )
      setSeekTime({ seconds: selectedMoment.start_time });
  }, [playTime]);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const handleCollapseClick = () => {
    setIsCollapsed(!isCollapsed);
  };

  function onChatDone() {
    if (!isChatReady) setIsChatReady(true);
  }

  useEffect(() => {
    if (momentId) {
      const moment = moments.find((m) => m.id === momentId);
      if (moment) setFocusedItem({ kind: "user", id: momentId });
    }
  }, [momentId]);

  return (
    <VStack w={"100%"}>
      <Flex
        className="results-page"
        w={"100%"}
        h={"calc(100vh - 4rem)"}
        p={4}
        overflowX={isCollapsed ? "hidden" : undefined}
      >
        <TourButton right={"1.1rem"} bottom={16} />
        <Flex
          className="results-list"
          width="100%"
          pr={4}
          minW="360px"
          maxW="440px"
          borderRightWidth={1}
        >
          <MomentPanel
            moments={filteredMoments}
            recommendedMoments={filteredRecommendedMoments}
            toggleMoment={toggleMoment}
            totalMoments={activeMoments.length}
            focusedCallback={setFocusedItem}
            focusLastMoment={focusLastMoment}
            focusedItem={
              focusedItem !== null && focusedItem.kind !== "temporary" ? focusedItem.id : null
            }
            deleteMoments={deleteMoments}
            updateMoment={updateMoment}
            searchId={videoID}
            filters={filters}
            addFilter={addFilter}
            removeFilter={removeFilter}
            videoInfo={videoInfo}
            recommendedMomentsLoading={recommendedSub.loading}
            acceptMoments={acceptMoments}
            momentTabIndex={momentTabIndex}
            setMomentTabIndex={setMomentTabIndex}
            pauseMedia={() => setPausePlayer({ pause: true })}
            recommendedAmount={recommendedAmount}
            setRecommendedAmount={setRecommendedAmount}
            minRecommendedAmount={Math.min(minRecommendedMoments, recommendedMoments.length)}
            maxRecommendedAmount={recommendedMoments.length}
          />
        </Flex>
        <Flex w="100%" className="media-panel">
          <MediaPanel
            videoInfo={videoInfo}
            stopSearch={stopSearch}
            isLoopingLoading={isLoopingLoading}
            moments={filteredMoments}
            recommendedMoments={
              momentTabIndex === 1
                ? filteredRecommendedMoments.filter((moment) => !moment.rejected)
                : []
            }
            videoID={videoID}
            handleFocusedChange={setFocusedItem}
            selectedMoment={selectedMoment}
            selectedMomentType={focusedItem === null ? null : focusedItem.kind}
            updateSelectedMoment={updateSelectedMoment}
            addMoment={addMoment}
            defaultMomentDuration={defaultMomentDuration}
            pausePlayer={pausePlayer}
            playTime={playTime}
            setPlayTime={setPlayTime}
            seekTime={seekTime}
            graphSeekCallback={handleHypeGraphSeek}
            looping={looping}
            setLooping={setLooping}
            signalLoading={signalSub.loading}
            signalError={Boolean(signalSub.error)}
            signalData={signalData}
          />
        </Flex>
        <Tooltip label={isCollapsed ? "Show panel" : "Hide panel"} placement={"left"}>
          <IconButton
            aria-label="Collapse Chat and Speech panel"
            onClick={handleCollapseClick}
            right={isCollapsed ? 0 : "-1.05rem"}
            p={0}
            m={0}
            h={40}
            alignSelf={"center"}
            minW={4}
            w={4}
            icon={isCollapsed ? <ChevronLeftIcon boxSize={5} /> : <ChevronRightIcon boxSize={5} />}
            borderRadius={"full"}
            variant={"ghost"}
            position={isCollapsed ? "absolute" : undefined}
            overflow={"hidden"}
          />
        </Tooltip>
        <Flex
          className="chat-speech-panel"
          w={isCollapsed ? "0px" : "100%"}
          h={isCollapsed ? "0px" : undefined}
          pl={isCollapsed ? 0 : 4}
          ml={isCollapsed ? 0 : 0}
          minW={isCollapsed ? "0px" : "360px"}
          maxW={isCollapsed ? "0px" : "440px"}
          borderLeftWidth={isCollapsed ? "0px" : 1}
          position={isCollapsed ? "absolute" : undefined}
          right={isCollapsed ? "-100%" : undefined}
          display={isCollapsed ? "none" : undefined}
        >
          <ChatPanel
            videoInfo={videoInfo}
            playTime={playTime}
            seek={(seconds) => setSeekTime({ seconds })}
            isCollapsed={isCollapsed}
            isChatReady={videoInfo && (isChatReady || videoInfo.status === "stopped")}
            isSpeechReady={videoInfo && videoInfo.asr_transcript && videoInfo.status === "stopped"}
          />
        </Flex>
      </Flex>
    </VStack>
  );
};
export default Results;
